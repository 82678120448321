import { Injectable } from '@angular/core';
import { Subject, delay, iif, merge, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private counter: number = 0;
  private loading: boolean = false;

  private loading$ = new Subject<boolean>();
  
  constructor() {
    const loading$ = this.loading$;
    const delayedLoading$ = loading$.pipe(
      switchMap((loading) =>
        iif(() => loading, of(loading).pipe(delay(900)), of(loading))
      )
    );
    delayedLoading$.subscribe((loading) => {
      if (loading) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    });
  }

  setLoading(loading: boolean) {
    if (loading) {
      this.counter++;
    } else {
      this.counter--;
    }
    if (this.counter < 0) {
      this.counter = 0;
    }

    this.loading$.next(this.counter > 0);
  }

  getLoading(): boolean {
    return this.loading;
  }
}
