import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { ErrorDialogService } from './error-dialog.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(
    private errorDialogService: ErrorDialogService,
    private zone: NgZone
  ) {
    super();
  }

  override handleError(error: any) {
    if (error instanceof HttpErrorResponse) {
      this.zone.run(() => {
        this.errorDialogService.openSnackBar(
          error?.message || 'Undefined error',
          error?.status
        );
      });
    } else {
      // TODO Maybe display a dialog box with the error message for these kind of errors
      super.handleError(error);
    }
  }
}
