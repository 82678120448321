import { HttpErrorResponse, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, finalize, throwError } from 'rxjs';
import { LoaderService } from './loader.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private router: Router, private loadingService: LoaderService, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    var headers:HttpHeaders = request.headers;

    if(request.url.startsWith('/api/')) {
      headers = headers.set('ngsw-bypass', '1');
    }

    const accessToken = this.authService.getAccessToken();
    if (accessToken) {
      headers = headers.set('Authorization', `Bearer ${accessToken}`)
    }

    let modifiedRequest: any = request.clone({
      headers: headers,
    });

    this.loadingService.setLoading(true);
    return next.handle(modifiedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // redirect to the login route
          this.router.navigate(['/auth/magic-login']);
        }

        return throwError(() => error);
      }),
      finalize(() => {
        this.loadingService.setLoading(false);
      })
    );
  }
}
