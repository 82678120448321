import { NgModule } from '@angular/core';
import { inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ServiceWorkerService } from './services/service-worker.service';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [() => !inject(ServiceWorkerService).markedForUpdate],
    children: [
      {
        path: 'auth',
        resolve: {
          crispChat: () => true
        },
        loadChildren: () =>
          import('./auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'backoffice',
        children: [
          {
            path: 'admin',
            title: 'Admin - Coolivery',
            loadChildren: () =>
              import('./admin/admin.module').then((m) => m.AdminModule),
          },
          {
            path: 'packer',
            title: 'Packer - Coolivery',
            loadChildren: () =>
              import('./packer/packer.module').then((m) => m.PackerModule),
          },
          {
            path: 'procurement',
            title: 'Nabavka - Coolivery',
            loadChildren: () =>
              import('./procurement/procurement.module').then(
                (m) => m.ProcurementModule
              ),
          },
          {
            path: 'sales',
            loadChildren: () =>
              import('./sales/sales.module').then((m) => m.SalesModule),
          },
          {
            path: 'support',
            loadChildren: () =>
              import('./support/support.module').then((m) => m.SupportModule),
          }
        ],
      },
      // Must be last because of the catch-all route
      {
        path: '',
        title: 'Website - Coolivery',
        resolve: {
          crispChat: () => true
        },
        loadChildren: () =>
          import('./website/website.module').then((m) => m.WebsiteModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
